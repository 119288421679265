.MessagePreview-link {
    color: black;
    text-decoration: none;
}

.MessagePreview-link:hover {
   color:       #212121;
}


.custom-hover-effect:hover {
    background-color: #eef5ff;
  }

  
#customInputContainerTime.timepicker {
    border: 1px solid #bfc9e2;
    border-radius: 5px;
    height: 28px;
    margin-bottom: 7px;
    padding: 5px;
    position: relative;
    z-index: 2;
    background: transparent;
    width: 100px;
}

#customInputContainerTime .timepicker[type=time]::-webkit-calendar-picker-indicator {
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}
 
#body-card-quick-text{
    padding: 0% !important;
}

.active-bg:active{
    background-color: #EDF0F3 !important;
    color: #000000 !important;
}