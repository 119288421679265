@import "../../_metronic/_assets/sass/style.react.scss";

.media-grid__item {
    width: 110px;
    height: 110px;
}

.upload-icon {
    font-size: 36px;
}

.third-party-upload-icon {
    font-size: 24px;
}

.drag-area {
    border: dashed 2px $primary;
}
.close-icon {
    position: absolute;
    top: 5px; /* Adjust the top position as needed */
    right: 5px; /* Adjust the right position as needed */
    cursor: pointer !important;
    color: red !important;
    padding: 2px;
    border-radius: 50%; /* Optional: Makes it circular */
    transition: background-color 0.3s ease; /* Optional: Add a transition effect */
}
.drop-area {
    border: solid 2px $primary;
    background: repeating-linear-gradient(
                    45deg,
                    white,
                    white 10px,
                    rgba(54, 153, 255, 0.1) 10px,
                    rgba(54, 153, 255, 0.1) 20px
    );
}

.preview-image {
    object-fit: contain;
}
