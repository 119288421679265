.parties-schedule {
    .search-btn-container {
        width: 52.5px;
        height: 52.5px;
        min-width: 52.5px;
    }

    .search-icon {
        font-size: 1.9rem;
    }

    .search-btn {
        border-radius: 12px;

        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .search-input {
        border-radius: 12px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .input-group {
        border-radius: 12px;
    }
}
.faded
{
    color: #b1b1b1;
    font-weight: 600;
}

  .accordion-header {
    width: 100%;
}
.image-container {
    margin-right: 10px;
    display: flex;
    float: left;
}
  .accordionHead {
    color: #000;
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0px;
}
.image-container img {
    width: 48px;
}
.accordion-content {
    text-align: left;
}
.accordion .card .card-header .accordion-header,.accordion .card .card-header {
    background: none !important;
    border: none;
}
.accordion .card {
    border: none !important;
}
.accordion .card .card-body {
    padding: 0px 80px !important;
}
.counter p {
    background: #edecec;
    font-weight: 600;
    padding: 5px;
    border-radius: 5px;
}

button.accordion-header.btn.btn-secondary {
    padding: 0px;
}
.partyTree.accordion {
    margin: 40px 0px 0px 0px;
}
.accordionSubHead {
    font-weight: 600;
    font-size: 14px;
}
.partyMessageTree .counter p {
    padding: 3px 7px;
    font-size: 10px;
    margin-right: 10px;
}
.accordion-content p {
    margin: 0px;
}
.partyMessageTree.accordion .card-body {
    padding: 0px 40px 15px 28px!important;
}
.partyMessageTree.accordion .card-body p {
    margin: 0px;
    font-size: 15px;
    color: #707070;
}
.scroller {
    overflow-y: scroll;
    height: 600px;
    margin-top: 10px
}

.PrefectScrollbar_Custom {
    overflow-y: auto;
    height: 600px;
    margin-top: 10px
}