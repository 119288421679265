@import "../../_metronic/_assets/sass/style.react.scss";

.msg-right {
    align-self: flex-end;
    background: #1e8cff;
}

.msg-left {
    align-self: flex-start;
    background: #003f90;
}

.msg-left {
    max-width: 85%;
    position: relative;
    &::after {
        content: '';
        display: block;
        background: $primary;
        width: 10px;
        height: 10px;
        position: absolute;
        bottom: -3px;
        left: 0;
    clip-path: polygon(20% 0, 100% 35%, 0 100%);
    }
}


.msg-right {
    max-width: 85%;
    position: relative;
    &::after {
        content: '';
        display: block;
        background: $secondary;
        width: 10px;
        height: 10px;
        position: absolute;
        bottom: -3px;
        right: 0;
        clip-path: polygon(80% 0, 0 35%, 100% 100%);
    }
}
