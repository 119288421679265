// Theme Styles
@import "../_metronic/_assets/sass/style.react.scss";

// Fonts
@font-face {
    font-family: "Poppins";
    src: url("../fonts/poppins-black-webfont.woff2") format("woff2"),
        url("../fonts/poppins-black-webfont.woff") format("woff"),
        url("../fonts/Poppins-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/poppins-semibold-webfont.woff2") format("woff2"),
        url("../fonts/poppins-semibold-webfont.woff") format("woff"),
        url("../fonts/Poppins-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/poppins-medium-webfont.woff2") format("woff2"),
        url("../fonts/poppins-medium-webfont.woff") format("woff"),
        url("../fonts/Poppins-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Poppins";
    src: url("../fonts/poppins-regular-webfont.woff2") format("woff2"),
        url("../fonts/poppins-regular-webfont.woff") format("woff"),
        url("../fonts/Poppins-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}

@import "./typographyStyles.scss";
@import "./layout.scss";
@import "./utils.scss";
@import "./custom.scss";

* {
    box-sizing: border-box;
}

.body {
    font-family: "Poppins", sans-serif;
    font-weight: normal;
}

.list-view {
    overflow-y: auto;
    height: 600px;
    margin-top: 5px;
    &__item:hover {
        background: #e0e2ef;
    }
    @include media-breakpoint-down(md) {
        max-height: 600px;
        height: 100%;
    }
}

.login-form {
    max-width: 400px;
    width: 100%;
}

.small-image {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    margin-right: 15px;
    position: relative;

    .msg-count {
        position: absolute;
        width: 27px;
        height: 27px;
        min-width: 27px;
        min-height: 27px;
        top: 0;
        right: 0;
        transform: translate(40%, -40%);
    }
}

.skeleton {
    display: inline-block;
    height: 1em;
    position: relative;
    overflow: hidden;
    background-color: #dddbdd;

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateX(-100%);
        background-image: linear-gradient(
            90deg,
            rgba(#fff, 0) 0,
            rgba(#fff, 0.2) 20%,
            rgba(#fff, 0.5) 60%,
            rgba(#fff, 0)
        );
        animation: shimmer 2s infinite;
        content: "";
    }

    @keyframes shimmer {
        100% {
            transform: translateX(100%);
        }
    }
}

.img-circle-border {
    object-fit: cover;
    border: $primary 3px solid;
}

.semi-transparent {
    background-color: rgba(0, 0, 0, 0.3);
}

.contain-image {
    object-fit: contain;
}
.modal-backdrop{
    z-index: 999999998;
}
.modal{
    z-index: 999999999;
}

:root {
    --scrollbar-width: 6px;
  }
  
  /* For WebKit browsers (Chrome, Safari, etc.) */
  ::-webkit-scrollbar {
    width: var(--scrollbar-width);
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #cbcbcb;
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #9b9b9b;
  }