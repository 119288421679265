@import "../../_metronic/_assets/sass/style.react.scss";

.script__new-template-msg-container {
    border: 2px dashed #BDBDBD;
}

.msg-preview {
    max-width: 85%;
    &::after {
        content: '';
        display: block;
        background: $primary;
        width: 10px;
        height: 10px;
        position: absolute;
        bottom: -3px;
        right: 0;
        clip-path: polygon(80% 0, 0 35%, 100% 100%);
    }
}
.left-title-bar {
    margin-left: 70px;
}
.right-player .btn {
    padding: 5px;
}
.grayed-out {
    background-color: #f2f2f2; /* Change this to your desired gray color */
    opacity: 0.6; /* Change this value to control the opacity of the grayed-out div */
    pointer-events: none; /* This will disable pointer interactions with the div */
}