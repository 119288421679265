.small_event {
    display: inline-block;

    & > .fc-event-time {
        display: none;
    }

    & > .fc-event-title {
        display: none;
    }
}

/* CSS for FullCalendar scrollbar */ 
.fc .fc-scroller::-webkit-scrollbar {
    width: 6px;    
} 

.fc .fc-scroller::-webkit-scrollbar-track {
    background: #f1f1f1; 
}
 
.fc .fc-scroller::-webkit-scrollbar-thumb {
    background: #cbcbcb;  
    border-radius: 10px;  
}
 
.fc .fc-scroller::-webkit-scrollbar-thumb:hover {
    background: #9b9b9b; 
}
 
