.phone-card {
    border: 9px #D3E5FB solid;
    background: #D3E5FB;
}
ul.pagination {
    margin-top: 20px;
    text-align: center;
}
.centerPagination {
    margin: auto !important;
    display: table;
}
.react-confirm-alert-overlay {
    z-index: 99999  !important;
}