.nav-bar__container {
    min-height: 60px;
}

.nav-bar {
    min-height: 60px;

    &__btn {
        height: 48px;
        width: 48px;
        &::after {
            display: none;
        }
    }

    &__link-popover {
        color: rgba(0, 0, 0, 0.5);
        margin: 0 8px;
        padding-bottom: 0.5rem;
        position: relative;

        &::after {
            content: "";
            display: block;
            border-radius: 5px;
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 0;
            height: 2px;
            background: #000;
            transition: .2s;
        }
    }

    &__link {
        margin: 0 8px;
        position: relative;

        &:hover {
            &::after {
                width: 100%;
                left: 0;
            }
        }

        &::after {
            content: "";
            display: block;
            border-radius: 5px;
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 0;
            height: 2px;
            background: #000;
            transition: .2s;
        }
    }

    &__link-active {
        color: rgba(0, 0, 0, 0.7);

        & > h6 {
            color: rgba(0, 0, 0, 0.7);
        }

        &::after {
            width: 100%;
            left: 0;
        }
    }
}
