.custom-multiselect {
    border: 2px solid #ccc; /* Border style */
    border-radius: 8px; /* Border radius */
  }
  .e-multiselect.e-input-group.e-control-wrapper {
    border: 2px solid #ccc !important;
    border-radius: 8px !important;
    padding: 6px !important;
    height: auto !important;
    overflow: hidden;
  }
.control-pane #multisection.control-section, .control-section #custom-filtering {
  width: 80%;
  margin: 0 auto;
  min-width: 185px;
}
.e-bigger .control-pane #multisection.control-section,
.e-bigger .control-section #custom-filtering {
  width: 100%;
}

.announcementTree.accordion .card {
  width: 100%;
}
.announcementTree .card .card-body {
  padding: 0px 10px 0px 55px!important;
}
.announcemntTitle
{
  padding: 6px 0px !important;
}
.announcementContactList .contactTitle
{
  border-bottom: 2px solid #ccc;
}
.announcementTree .popoverAction
{
  padding: 6px 3px;
}
.googleContacts button {
  border: 1px solid #ccc !important;
  width: 54%;
    text-align: center !important;
    font-size: 17px !important;
    font-weight: bold !important;
    border-radius: 5px !important;
    display: inline-flex;
    padding: 15px 15px;
}
.googleContacts button  span{
  font-weight: 700 !important;
  padding: 0px 16px;
  font-size: 16px;
}
.googleContacts {
  text-align: center;
}
button.ml-3.more-actions.rounded-lg.shadow-sm.search-btn-container.btn.btn-primary {
  display: inherit;
  padding: 0px;
}