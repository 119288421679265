@import "../../_metronic/_assets/sass/style.react.scss";

.middle-element {
    @include media-breakpoint-down(sm) {
        border-top: 1px solid #C8E3FF;
        border-bottom: 1px solid #C8E3FF;
        border-right: 0;
        border-left: 0;
    }

    @include media-breakpoint-up(sm) {
        border-right: 1px solid #C8E3FF;
        border-left: 1px solid #C8E3FF;
        border-top: 0;
        border-bottom: 0;
    }
}
