h1 {
    //styleName: H1;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: 42px;
    letter-spacing: 0px;
    text-align: left;
}

h2 {
    //styleName: H2;
    font-family: Poppins;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0px;
    text-align: left;
}

h3 {
    //styleName: H3;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: left;
}

h4 {
    //styleName: H4;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: left;
}

h5 {
    //styleName: H5;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0px;
    text-align: left;
}

h6 {
    //styleName: H6;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
}

.p1 {
    //styleName: P1;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: left;
}

.p2 {
    //styleName: P2;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0px;
    text-align: left;
}

.p3 {
    //styleName: P3;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
}

.p4 {
    //styleName: P4;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
}

.p5 {
    //styleName: P5;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
}
