.w-10 {
    width: 10%;
}

.w-15 {
    width: 15%;
}

.w-20 {
    width: 20%;
}

.w-80 {
    width: 80%;
}

.w-85 {
    width: 85%;
}

.w-90 {
    width: 90%;
}

.w-95 {
    width: 95%;
}
